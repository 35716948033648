(() => {

  let sections = Array.from(document.querySelectorAll("body > .content > [class*=component-]"));

  const dims = {
    w:window.innerWidth,
    h:window.innerHeight
  }

  if (window.innerWidth >= 1330 && window.innerHeight >= 768) {

    const hero = sections[0];
    const impactStories = sections[1];

    tl1 = gsap.timeline({
      scrollTrigger:{
        trigger: ".content",
        start: "top top",
        end: `+=${dims.h}`,
        pin: ".component-hero",
        scrub: true,
        snap: true,
        anticipatePin:1,
        pinSpacing:true
      }
    }).to('.component-hero .gallery__item__inner',{
      transform:"translateY(-100%)",
      ease: 'power1'
    },1).to('.component-hero .gallery__highlight',{
      transform:"translateY(-100%)"
    },1).to('.component-hero .hero__content',{
      transform:"translateY(-100%)"
    },1).to('.component-hero .component__inner',{
      opacity:0
    },1).to('.component-hero',{
      background:'#F7F6F1',
      opacity:0,
      pointerEvents:'none',
      zIndex:1
    },2).to('.component-impact-stories',{
      zIndex:3
    },2);

    // tl2 = gsap.timeline({
    //   scrollTrigger:{
    //     trigger: ".component-impact-stories",
    //     start: "top top",
    //     end: `+=${dims.h}`,
    //     pin: true,
    //     scrub: true,
    //     pinSpacing:true,
    //     anticipatePin:1,
    //     snap:true
    //   }
    // })
  }

  function preloadImage(url) {
    var img=new Image();
    img.src=url;
  }

  ['/wp-content/themes/impactreport-2022-child/build/images/PieChart-Separates-02.png',
        '/wp-content/themes/impactreport-2022-child/build/images/PieChart-Separates-03.png',
        '/wp-content/themes/impactreport-2022-child/build/images/PieChart-Separates-04.png',
        '/wp-content/themes/impactreport-2022-child/build/images/PieChart-Separates-05.png'].forEach(img => preloadImage(img));

})()